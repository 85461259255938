import { Routes } from '@angular/router';
import { CreatePasswordComponent } from './pages/public/create-password/create-password.component';
import { CheckoutComponent } from './pages/public/checkout/checkout.component';
import { MedicationFeedbackComponent } from './pages/public/medication-feedback/medication-feedback.component';
import { BookgeneticsGuard } from './core/guards/bookgenetics-redirect.guard';
import { BookingComponent } from './pages/public/booking/booking.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/main/main.routes').then(m => m.MAIN_ROUTES)
  },
  {
    path: 'bookgenetics',
    canActivate: [BookgeneticsGuard],
    component: BookingComponent
  },
  {
    path: 'create-password',
    component: CreatePasswordComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },
  {
    path: 'medication-feedback',
    component: MedicationFeedbackComponent
  },
  {
    path: 'survey',
    loadChildren: () =>
      import('./pages/survey/survey.routes').then(m => m.SURVEY_ROUTES)
  },
  { path: '**', redirectTo: '404' }
];
