import { Component, OnInit, inject } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { LoadingService } from './core/services/loading.service';
import { MessageService } from './core/services/message.service';
import { MatIconModule } from '@angular/material/icon';
import { MaintenanceDialogComponent } from './core/components/maintenance-dialog/maintenance-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Platform } from '@angular/cdk/platform';
import { LocalstorageService } from './core/services/localstorage.service';

interface HeaderMessage {
  message: string;
  className: string;
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterModule, MatIconModule],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  messageService = inject(MessageService);
  viewportScroll = inject(ViewportScroller);
  loadingService = inject(LoadingService);
  private dialog = inject(MatDialog);
  public platform = inject(Platform);
  public localStorage = inject(LocalstorageService);

  loadingHttp!: boolean;
  messageDisplay!: HeaderMessage;
  constructor() {
    this.loadingService.loadingHttp.subscribe((value: boolean) => {
      setTimeout(() => {
        if (value !== this.loadingHttp) {
          this.loadingHttp = value;
        }
      });
    });
  }

  ngOnInit(): void {
    this.setPageOffset();
    this.messageService.headerMessage.subscribe((value: HeaderMessage) => {
      this.messageDisplay = value;
    });
  }

  setPageOffset() {
    const headerOffset = this.platform.IOS || this.platform.ANDROID ? 80 : 200;
    this.viewportScroll.setOffset([0, headerOffset]);
  }

  maintenanceDialog() {
    const dialogRef = this.dialog.open(MaintenanceDialogComponent, {
      width: '690px'
    });
  }
}
