import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot
} from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { BOOKING_LINK, WINDOW } from '../constants/config';

@Injectable({
  providedIn: 'root'
})
class BookgeneticsService {
  private window = inject(WINDOW);
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (state.url.includes('bookgenetics')) {
      this.window.location = BOOKING_LINK;
      return false;
    }
    return true;
  }
}

export const BookgeneticsGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean => {
  return inject(BookgeneticsService).canActivate(next, state);
};
