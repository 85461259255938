import { contactFormStep } from './quiz';

const immunodeficiencyFamilyOptions = [
  {
    title: 'Bloom Syndrome',
    id: 1
  },
  {
    title: 'Fanconi Anemia (Types D1, C, N)',
    id: 2
  },
  {
    title: 'Cystic Fibrosis',
    id: 3
  },
  {
    title: 'Hemophilia B',
    id: 4
  },
  {
    title: 'Immune cancers – Leukemia, Lymphoma, Myeloma',
    id: 5
  },
  {
    title: 'Factor V Leiden Thrombophilia',
    id: 6
  },
  {
    title: 'G6PD Deficiency',
    id: 7
  },
  {
    title: 'Glycogen Storage Disease Type I (Von Gierke Disease)',
    id: 8
  },
  {
    title: 'Myeloproliferative Disorders (e.g., Polycythemia Vera)',
    id: 9
  },
  {
    title: 'Lynch Syndrome',
    id: 10
  },
  {
    title: 'MYD88 Deficiency',
    id: 11
  },
  {
    title:
      'PLAID (PLCG2-Associated Antibody Deficiency and Immune Dysregulation)',
    id: 12
  },
  {
    title: 'PTEN Hamartoma Tumor Syndrome (e.g., Cowden Syndrome)',
    id: 13
  },
  {
    title: 'Familial Platelet Disorder with Propensity to AML',
    id: 14
  },
  {
    title: 'Congenital Amegakaryocytic Thrombocytopenia',
    id: 15
  },
  {
    title: 'Dyskeratosis Congenita',
    id: 16
  },
  {
    title: 'Autoimmune Lymphoproliferative Syndrome-like Disease',
    id: 17
  },
  {
    title: 'Guillain Barre Syndrome',
    id: 18
  },
  {
    title: 'None of the above',
    id: 19,
    none_selected: true
  }
];

const immunodeficiencyOptions = [
  {
    title: 'Recurrent infections (e.g., sinus, lung)',
    id: 1
  },
  {
    title: 'Bone marrow failure',
    id: 2
  },
  {
    title: 'Thick mucus and chronic lung issues',
    id: 3
  },
  {
    title:
      'Developmental abnormalities (e.g., short stature, characteristic facial features)',
    id: 4
  },
  {
    title:
      'Bleeding tendencies (e.g., prolonged bleeding, easy bruising, hemarthrosis)',
    id: 5
  },
  {
    title:
      'Increased blood clot risk (e.g., venous thrombosis, pulmonary embolism)',
    id: 6
  },
  {
    title: 'Immune dysregulation (e.g., autoimmunity, cold-induced urticaria)',
    id: 7
  },
  {
    title: 'Enlarged liver (hepatomegaly)',
    id: 8
  },
  {
    title: 'Fatigue, jaundice, and hypoglycemia',
    id: 9
  },
  {
    title: 'Macrocephaly and abnormal tumor development (benign/malignant)',
    id: 10
  },
  {
    title: 'Chronic cough post COVID',
    id: 11
  },
  {
    title: 'Chronic sinus infections',
    id: 12
  },
  {
    title: 'Chronic respiratory illnesses',
    id: 13
  },
  {
    title: 'Asthma',
    id: 14
  },
  {
    title: 'Digestive Problems',
    id: 15
  },
  {
    title: 'None of the above',
    id: 16,
    none_selected: true
  }
];

const neurologicalFamilyOptions = [
  {
    title: `Alzheimer's Disease`,
    id: 1
  },
  {
    title: 'Amyotrophic Lateral Sclerosis (ALS)',
    id: 2
  },
  {
    title: 'Autism Spectrum Disorder (ASD)',
    id: 3
  },
  {
    title: 'Ataxia-telangiectasia',
    id: 4
  },
  {
    title: 'Charcot-Marie-Tooth Disease',
    id: 5
  },
  {
    title: 'Dementia',
    id: 6
  },
  {
    title: 'Fragile X Syndrome',
    id: 7
  },
  {
    title: `Huntington's Disease`,
    id: 8
  },
  {
    title: `Parkinson's Disease`,
    id: 9
  },
  {
    title: 'Rett Syndrome',
    id: 10
  },
  {
    title: 'Spinocerebellar Ataxia',
    id: 11
  },
  {
    title: 'Spinal Muscular Atrophy',
    id: 12
  },
  {
    title: 'Tay-Sachs Disease',
    id: 13
  },
  {
    title: `Wilson's Disease`,
    id: 14
  },
  {
    title: `X-linked Intellectual Disability`,
    id: 15
  },
  {
    title: 'None of the above',
    id: 16,
    none_selected: true
  }
];

const neurologicalOptions = [
  {
    title: 'Developmental delays and intellectual disability',
    id: 1
  },
  {
    title: 'Memory Loss',
    id: 2
  },
  {
    title: 'Depression',
    id: 3
  },
  {
    title: 'Insomnia',
    id: 4
  },
  {
    title:
      'Muscle-related issues (e.g., hypotonia, spasticity, weakness, atrophy)',
    id: 5
  },
  {
    title: 'Cognitive decline (e.g., memory loss, personality changes)',
    id: 6
  },
  {
    title: 'Movement disorders (e.g., dystonia, tremors, ataxia)',
    id: 7
  },
  {
    title: 'Seizures and epilepsy',
    id: 8
  },
  {
    title:
      'Behavioral and mood disorders (e.g., autism-related, psychiatric disturbances)',
    id: 9
  },
  {
    title: 'Sensory loss (e.g., visual impairment, hearing loss)',
    id: 10
  },
  {
    title: 'Respiratory issues (e.g., failure, feeding difficulties)',
    id: 11
  },
  {
    title: 'Stroke-like episodes',
    id: 12
  },
  {
    title: 'Migraines with aura',
    id: 13
  },
  {
    title: 'Regression in motor skills or speech',
    id: 14
  },
  {
    title: 'Distinctive craniofacial features',
    id: 15
  },
  {
    title: 'None of the above',
    id: 16,
    none_selected: true
  }
];

export function GeneticsSteps(
  user: any | null,
  isLoggedIn: boolean,
  isEdit?: boolean
): any[] {
  const steps = [
    contactFormStep,
    {
      id: 2,
      step_id: 3,
      registration_step: true,
      form_id: 3,
      with_proggress: {
        step: 2
      }
    },
    {
      id: 3,
      form_id: 12,
      step_edit_start: true,
      title:
        'Have you ever taken a genetic test before help identify genes associated with Immunodeficiency or Neurological conditions?',
      list: [
        {
          id: 1,
          title: 'Yes',
          step_id: null,
          forbidden: 6,
          content: {
            text: 'Based on the answers provided you are currently not eligible for genetic testing at this time'
          }
        },
        {
          id: 2,
          step_id: 4,
          title: 'No'
        }
      ]
    },
    {
      id: 4,
      form_id: 12,
      title: 'Do you make your own medical decisions?',
      list: [
        {
          id: 1,
          title: 'Yes',
          step_id: 5
        },
        {
          id: 2,
          title: 'No',
          step_id: null,
          forbidden: 6,
          content: {
            text: 'Based on the answers provided you are currently not eligible for genetic testing at this time'
          }
        }
      ]
    },
    {
      title:
        'Have you or a member of your immediate family ever been diagnosed with any of the following immunodeficiency disorders? Click all that apply.',
      id: 5,
      step_id: 6,
      form_id: 6,
      list: immunodeficiencyFamilyOptions
    },
    {
      title:
        'Do you experience any of the following symptoms associated with an immunodeficiency disorder? Click all that apply.',
      id: 6,
      step_id: 7,
      form_id: 6,
      list: immunodeficiencyOptions
    },
    {
      title:
        'Have you or a member of your immediate family ever been diagnosed with any of the following neurological disorders? Click all that apply.',
      id: 7,
      step_id: 8,
      form_id: 6,
      list: neurologicalFamilyOptions
    },
    {
      title:
        'Do you experience any of the following symptoms associated with an neurological disorders? Click all that apply.',
      id: 8,
      step_id: 9,
      form_id: 6,
      list: neurologicalOptions
    },
    {
      title: 'What medications are you currently taking?',
      id: 9,
      step_id: 10,
      form_id: 7
    },
    {
      id: 10,
      step_id: null,
      end_step: true,
      form_id: 25
    }
  ];
  return steps;
}
