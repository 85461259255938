import { Component, inject, OnInit } from '@angular/core';
import { BOOKING_LINK, WINDOW } from 'src/app/core/constants/config';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-booking',
  standalone: true,
  imports: [],
  templateUrl: './booking.component.html'
})
export class BookingComponent implements OnInit {
  private authService = inject(AuthService);
  private window = inject(WINDOW);
  redirect() {
    this.window.location = BOOKING_LINK;
  }

  ngOnInit(): void {
    this.authService.pageIndexLoader();
  }
}
