<div class="simple-header justify-content-center">
  <img src="assets/images/logo.svg" class="simple-header-logo" alt="" />
</div>
<div class="simple-page-content no-bottom">
  <div class="question-content text-center">
    <div class="bs-xs-40 bs-sm-60"></div>
    <button
      type="submit"
      class="app-button btn-primary btn-lg min-width"
      (click)="redirect()"
    >
      Boocking
    </button>
  </div>
</div>
